import styled from 'styled-components';

export const Container = styled.div`
  transition: all 0.25s;
  position: relative;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);

  }
`;